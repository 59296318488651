import Cookies from 'js-cookie';
const getBaseUrl = () => {
    const env = process.env.NODE_ENV
    if(env === 'production') {
        return ''
    } else {
        return 'http://localhost:8000'
    }
}

const getTokens = (type) => {
    if (type === 'refresh') {
        const refreshToken = localStorage.getItem('refresh-token');
        return {'refresh-token': refreshToken};
    } else if (type === 'access') {
        const accessToken = localStorage.getItem('access-token');
        return {'access-token': accessToken};
    }
}


const getRefresh = async () => {
    const refreshToken = getTokens("refresh");

    const apiUrl = `${getBaseUrl()}/api/auth/refresh`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken['refresh-token']}`
            },
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));

        return data;
    } catch (error) {
        throw error;
    }
};


const getDownloadFile = async (fileId) => {

    const apiUrl = `${getBaseUrl()}/api/download_file?file_id=${fileId}`;
    
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        })
    
        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
            
        // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }
    
        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);
    
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    
        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };

    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }
}





export {
    getBaseUrl,
    getTokens,
    getRefresh,
    getDownloadFile
};
